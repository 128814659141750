import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { Section } from "../styles/structures";
import { H4, P } from "../styles/text-styles";
import PillLabel from "./PillLabel";
import { Link, graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import styled from "styled-components";

export default function BlogList() {
  const data = useStaticQuery(
    graphql`
      query BlogListQuery {
        allContentfulBlogPost(sort: { fields: updatedAt, order: DESC }) {
          nodes {
            slug
            title
            description
            category
            thumbnail {
              url
            }
            updatedAt(formatString: "MMMM d, Y")

            author {
              name
              image {
                url
              }
            }
          }
        }
      }
    `
  );

  const blogPosts = data.allContentfulBlogPost.nodes;

  return (
    <Section>
      <Wrapper>
        {blogPosts.map((post: any) => (
          <BlogPost key={post.title}>
            <Link to={`/blog/${post.slug}`}>
              <BlogImage src={post.thumbnail.url} alt={post.title.name} />
            </Link>
            <TextPanel>
              <TitleWrapper>
                <PillLabel label={post.category} />
                <Link to={`/blog/${post.slug}`}>
                  <H4>{post.title}</H4>
                </Link>
              </TitleWrapper>
              <Description>{post.description}</Description>
              <BlogFooter>
                <div>
                  <DateText>{post.createdAt}</DateText>
                  <P>by {post.author.name.split(" ")[0]}</P>
                </div>
                <AuthorThumbnailWrapper>
                  <AuthorThumbnail
                    src={post.author.image.url}
                    alt={post.author.name}
                  />
                </AuthorThumbnailWrapper>
              </BlogFooter>
            </TextPanel>
          </BlogPost>
        ))}
      </Wrapper>
    </Section>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${breakpoints.md} {
    gap: 64px;
  }
`;

const BlogPost = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BlogImage = styled.img`
  border-radius: 12px 12px 0 0;
  width: 100%;

  ${breakpoints.md} {
    border-radius: 12px 0 0 12px;
  }
`;

const TextPanel = styled.div`
  background-color: ${colors.gray2};
  border-radius: 0 0 12px 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${breakpoints.md} {
    padding: 36px;
    border-radius: 0 12px 12px 0;
  }
`;

const BlogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled(P)`
  margin: 30px 0;
  color: ${colors.gray4};
`;

const DateText = styled(P)`
  color: ${colors.gray4};
`;

const AuthorThumbnailWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoints.md} {
    height: 48px;
    width: 48px;
    border-radius: 24px;
  }
`;

const AuthorThumbnail = styled.img`
  height: 40px;
  width: 40px;
  scale: 1.4;

  ${breakpoints.md} {
    height: 48px;
    width: 48px;
  }
`;
