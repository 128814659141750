import React from "react";
import styled from "styled-components";

import { H6 } from "../styles/text-styles";
import { colors } from "../styles/colors";

interface PillLabelProps {
  label: string;
  color?: string;
}

function hash(string: string): number {
  var hash = 0;
  var i = 0;
  var chr;

  if (!string || string.length === 0) return hash;

  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return Math.abs(hash);
}

export default function PillLabel(props: PillLabelProps) {
  const possibleColors = [
    colors.primary1,
    colors.primary2,
    colors.primary3,
    colors.primary4,
    colors.primary5,
    colors.primary6,
  ];
  const defaultColor =
    possibleColors[hash(props.label) % possibleColors.length];

  return (
    <CategoryPill color={props.color ? props.color : defaultColor}>
      <H6>{props.label}</H6>
    </CategoryPill>
  );
}

const CategoryPill = styled.div`
  background-color: ${props => props.color};
  border-radius: 16px;
  padding: 0 12px;
  max-width: fit-content;
`;
