import * as React from "react";

import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";
import BannerWithEyebrow from "../components/banners/BannerWithEyebrow";
import BlogList from "../components/BlogList";

export default function BExplore() {
  return (
    <Layout>
      <Seo title="Blog" description="Our team's created content" />
      <BannerWithEyebrow eyebrow="Here's what we have to say" headline="Blog" />
      <BlogList />
    </Layout>
  );
}
